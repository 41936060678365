import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import ImageBlock from "../../../components/image-block/"

import { psColour, wlColour, siteColour } from "../../../data/data.colours"

const RectoryPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			image01: file(relativePath: { eq: "backgrounds/sheep-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Rectory Farm"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={wlColour.hex}
					link={`/w-lamb/current-developments`}/>
				<Split>
					<ImageBlock 
						image={ data.image01.childImageSharp.fluid }
						position={`60% 90%`}/>
					<TextBlock 
						title={`Rectory Farm`}
						titleTag={ `h1` }
						text={`
							<h2>Puttenham</h2>
							<p>A group of redundant farm buildings in the Vale of Aylesbury is the perfect setting for a rural courtyard-style development of exclusive residential properties. An exciting joint venture with Laxton Properties is in the early stages of planning with the council.</p>
						`}
						bgColour={wlColour.slug}/>

				</Split>
			</Layout>
		</>
	)
}

export default RectoryPage
